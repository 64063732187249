import type { Values } from '../types/utils';

type ConfigServiceOpsKeys = Values<typeof OPS>;
type ConfigServiceExperimentKeys = Values<typeof EXPERIMENT>;
type ConfigServiceReleaseKeys = Values<typeof RELEASE>;
type ConfigServiceDataKeys = Values<typeof DATA>;

export type ConfigServiceKeys =
  | ConfigServiceOpsKeys
  | ConfigServiceExperimentKeys
  | ConfigServiceReleaseKeys
  | ConfigServiceDataKeys;

export const EXPERIMENT = {
  ACORN_AD_FILTERING_LOGIC_DISABLED: 'acorn_ad_filtering_logic_disabled',
  AUTOSUGGEST_EXP: 'AutosuggestExp',
  BOOKING_PANEL_LATENCY_DURATION_ACORN: 'booking_panel_latency_duration_acorn',
  BOOKING_PANEL_LATENCY_DURATION_BANANA: 'booking_panel_latency_duration_banana',
  CAN_SEE_PACKAGES_WEBSITE: 'can_see_packages_website',
  DESTINATION_PREVIEW_POC_VERSION: 'DestinationPreviewPOCVersion',
  ENABLE_ACORN_SONAR_V3_CREATE_SESSION_REQUEST: 'enable_acorn_sonar_V3_create_session_request',
  ENABLE_ACORN_SONAR_V3_POLL_REQUEST: 'EnableAcornSonarV3PollRequest',
  ENABLE_AIRLINE_BADGE_ON_DAYVIEW: 'EnableAirlineBadgeOnDayview',
  ENABLE_BOOKING_MULTI_CONFIDENCE_MESSAGING: 'enable_booking_multi_confidence_messaging',
  ENABLE_BPK_CHIP_GROUP_FOR_FILTERS: 'EnableBpkChipGroupForFilters',
  ENABLE_CALENDAR_PRICES: 'EnableCalendarPrices',
  ENABLE_CALENDAR_TYPE_IS_INITIAL_TRIP_TYPE: 'EnableCalendarTypeIsInitialTripType',
  ENABLE_CODESHARE: 'enable_codeshare',
  ENABLE_CROSS_VERTICAL_PARAMETERS_CARRYOVER: 'enable_cross_vertical_parameters_carryover',
  ENABLE_DESTINATION_PREVIEW_POC: 'EnableDestinationPreviewPOC',
  ENABLE_DYNAMIC_HEADER_TABS_REDIRECT: 'enable_dynamic_header_tabs_redirect',
  ENABLE_ENFORCE_TRIP_TYPE: 'EnableEnforceTripType',
  ENABLE_FSC_GC: 'enable_fsc_gc',
  ENABLE_GC_CAR_HIRE_RECOMMENDATIONS_IN_COMBINED_RESULTS:
    'EnableGCCarHireRecommendationsInCombinedResults',
  ENABLE_GC_HOTELS_RECOMMENDATIONS_IN_COMBINED_RESULTS:
    'EnableGCHotelsRecommendationsInCombinedResults',
  ENABLE_GOOGLE_ONE_TAP_FEDCM: 'enable_google_one_tap_fedcm',
  ENABLE_G_C_CAR_HIRE_RECOMMENDATIONS_IN_FLIGHTS_DAYVIEW_MWEB:
    'EnableGCCarHireRecommendationsInFlightsDayviewMweb',
  ENABLE_G_C_HOTELS_RECOMMENDATIONS_IN_FLIGHTS_DAYVIEW_MWEB:
    'EnableGCHotelsRecommendationsInFlightsDayviewMweb',
  ENABLE_HEADER_TABS_IN_COMBINED_RESULTS: 'enable_header_tabs_in_combined_results',
  ENABLE_HEADER_TABS_REDIRECT_TO_NEW_BROWSER_TAB: 'enable_header_tabs_redirect_to_new_browser_tab',
  ENABLE_HOMEPAGE_HERO_VIDEO: 'enable_homepage_hero_video',
  ENABLE_INLINE_HOTELS_ADS: 'enable_inline_hotels_ads',
  ENABLE_INVALID_JSON_RESPONSE_HANDLING: 'EnableInvalidJsonResponseHandling',
  ENABLE_LOGIN_FORM_GC_IN_BANANA: 'EnableLoginFormGCInBanana',
  ENABLE_LOGIN_FORM_GC_IN_PAS_BANANA: 'EnableLoginFormGCInPASBanana',
  ENABLE_MULTI_CITY_QUICK_LINK: 'EnableMultiCityQuickLink',
  ENABLE_NEW_TRANSLATIONS_XSELL__B_P: 'enable_new_translations_xsell_BP',
  ENABLE_NEW_TRANSLATIONS_XSELL__B_P_MWEB: 'enable_new_translations_xsell_BP_mweb',
  ENABLE_PQS_SURVEY_STAR_RATING: 'enable_pqs_survey_star_rating',
  ENABLE_PRICE_REFRESH: 'EnablePriceRefresh',
  ENABLE_PSS_UI_REFRESH: 'EnablePssUiRefresh',
  ENABLE_READ_BEFORE_BOOKING_BOTTOM_SHEET: 'EnableReadBeforeBookingBottomSheet',
  ENABLE_RELEVANT_DIGITAL_AS_PREBID_PROVIDER: 'enableRelevantDigitalAsPrebidProvider',
  ENABLE_SHARED_BAGGAGE_ICONS: 'EnableSharedBaggageIcons',
  ENABLE_SHARED_TCS_FILTER: 'EnableSharedTcsFilter',
  ENABLE_SINGLE_BUILD: 'enable_single_build',
  ENABLE_SINGLE_ROUTER: 'enable_single_router',
  ENABLE_SPONSORED_BOOKING_PANEL_ADVERT: 'enable_sponsored_booking_panel_advert',
  ENABLE_TCS: 'EnableTcs',
  ENABLE_TOTAL_COST: 'EnableTotalCost',
  ENABLE_TOTAL_COST_BAGGAGE_FILTERS: 'EnableTotalCostBaggageFilters',
  ENABLE_TOTAL_COST_SONAR_V3: 'EnableTotalCostSonarV3',
  ENABLE_UPSELLS_EXPANDABLE_PRICING_OPTION_ACORN: 'EnableUpsellsExpandablePricingOption_Acorn',
  ENABLE_WEB_TO_APP_BANNERS: 'enable_web_to_app_banners',
  ENABLE_WEB_TO_APP_BANNERS_BANANA: 'enable_web_to_app_banners_banana',
  HOTEL_REDIRECT_PRICE_CONSISTENCY: 'HotelRedirectPriceConsistency',
  INTERNAL_LINKING_LATEST_VERSION: 'internal_linking_latest_version',
  NATIVE_BOTTOM_CAR_HIRE_XSELL: 'native_bottom_car_hire_xsell',
  NATIVE_BOTTOM_HOTELS_XSELL: 'native_bottom_hotels_xsell',
  POSITION_OF_RAIL_BANNER: 'position_of_rail_banner',
  PRE_KONDO_BOOKING_PANEL_LOADING: 'PreKondoBookingPanelLoading',
  RENDER_SPONSORED_BOOKING_PANEL_ADVERT: 'render_sponsored_booking_panel_advert',
  SEND_TO_COMBINED_RESULTS: 'SendToCombinedResults',
  SHOW_AIRLINE_TAG: 'show_airline_tag',
  SHOW_COMBINED_RESULTS_BOTTOM_GOOGLE_ADVERT: 'ShowCombinedResultsBottomGoogleAdvert',
  SHOW_FARE_FAMILIES: 'show_fare_families',
  SHOW_PACKAGES_BANNER: 'show_packages_banner',
  SHOW_PARTNER_MESSAGES: 'ShowPartnerMessages',
  SHOW_PQS_MODAL: 'ShowPqsModal',
  SHOW_PQS_NUMERICAL_RATING: 'show_pqs_numerical_rating',
  SHOW_PQS_SURVEY_ON_ACORN: 'show_pqs_survey_on_acorn',
  SHOW_UNPRICED_OPTION: 'show_unpriced_option',
  TRACK_FLIGHT_ITINERARY_PRICING_OPTIONS: 'TrackFlightItineraryPricingOptions',
  UPSELL_AGENTS_INTEGRATIONS: 'upsell_agents_integrations',
  USE_ACCOMMODATION_RESULTS_IN_SAM_NATIVE_BOTTOM_HOTELS_XSELL:
    'use_accommodation_results_in_sam_bottom_hotels_xsell',
  USE_LOGIN_FORM_GC_IN_HEADER: 'use_login_form_gc_in_header',
  USE_PARTNER_TRUSTWORTHINESS: 'use_partner_trustworthiness',
  USE_PARTNER_TRUSTWORTHINESS_ACORN: 'use_partner_trustworthiness_acorn',
  USE_PREPRODPARTNER_MESSAGE_CONFIG_ACORN: 'use_preprodpartner_message_config_acorn',
  USE_PREPRODPARTNER_MESSAGE_CONFIG_BANANA: 'use_preprodpartner_message_config_banana',
  VALIDATE_FQS_TABS_PRICE: 'validate_fqs_tabs_price',
  WEB_TO_APP_BANNER_ID: 'web_to_app_banner_id',
  WEB_TO_APP_INITIALISATION_KEY_PROD: 'web_to_app_initialisation_key_prod',
  WEB_TO_APP_INITIALISATION_KEY_TEST: 'web_to_app_initialisation_key_test',
  XSELL_BOOKING_PANEL_LOADING: 'xsell_booking_panel_loading',
} as const;

export const RELEASE = {
  ENABLE_BUG_FIX: 'EnableBugFix',
  BASE_STYLESHEET_VERSION: 'Component_base-stylesheet_Version',
  FOOTER_VERSION: 'Component_footer_Version',
  NOT_FOUND_VERSION: 'Component_not-found_Version',
  ENABLE_DESTINATION_GUIDEBOOK: 'destination_guidebook_enabled',
  ENABLE_FILTERED_DAY_VIEW: 'filtered_day_view_enabled',
  ENABLE_SPONSORED_DESTINATION_DAY_VIEW: 'sponsored_destination_day_view_enabled',
  ENABLE_STREVDA_ADS_AFTER_GUIDEBOOK: 'strevda_ads_after_guidebook_enabled',
  ENABLE_US_BELARUS_BANNER_ACORN: 'EnableUSBelarusBannerAcorn',
  ENABLE_RUSSIA_BANNER_ACORN: 'EnableRussiaBannerAcorn',
  SEARCH_CONTROLS_VERSION: 'Component_flights-search-controls_Version',
  SEARCH_SUMMARY_BAR_VERSION: 'Component_flights-search-summary_Version',
  STREDVA_RUNTIME_VERSION: 'Component_strevda-runtime_Version',
  INLINE_PLUS_FR_FARE_POLICY_DISABLED_PARTNERS: 'inline_plus_fr_fare_policy_disabled_partners',
} as const;

export const OPS = {
  ALLOW_LOCAL_TLD_REDIRECT: 'AllowLocalTldRedirect',
  APP_STORE_LINK: 'appStoreLink',
  BLOCKED_MARKETING_CARRIER_LOGOS: 'BlockedMarketingCarrierLogos',
  BLOCKED_MARKETING_CARRIER_LOGOS_ACORN: 'BlockedMarketingCarrierLogosAcorn',
  BOOKING_CONFIDENCE_MESSAGE_EXPIRY_DAYS: 'BookingConfidenceMessageExpiryDays',
  BOOKING_CONFIDENCE_MESSAGE_EXPIRY_DAYS_ACORN: 'BookingConfidenceMessageExpiryDaysAcorn',
  CACHED_PRICES_ONLY: 'CachedPricesOnly',
  DAYVIEW_INITIAL_RESULT_COUNT: 'DAYVIEW_INITIAL_RESULT_COUNT',
  DEV_GOOGLE_CLIENT_ID: 'DEV_Google_Client_ID',
  DISABLE_PRICE_ALERTS_AND_S2L_FOR_BAGGAGE_SEARCH: 'disablePriceAlertsAndS2LForBaggageSearch',
  ENABLE_ACORN_GTM_INTEGRATION: 'EnableAcornGtmIntegration',
  ENABLE_ADJUST_HOTEL_PRICE: 'enableAdjustHotelPrice',
  ENABLE_BAGGAGE_FILTER_IN_SEARCH_RESULTS_DESKTOP: 'EnableBaggageFilterInSearchResultsDesktop',
  ENABLE_BANANA_GTM_INTEGRATION: 'EnableBananaGtmIntegration',
  ENABLE_COMBINED_EXPLORE_ANYTIME_SEARCH: 'EnableCombinedExploreAnytimeSearch',
  ENABLE_COMBINED_EXPLORE_MONTH_SEARCH: 'EnableCombinedExploreMonthSearch',
  ENABLE_COMPRESS_GRAPHIC_PROMO_MOBILE: 'EnableCompressGraphicPromoMobile',
  ENABLE_CONSENT_MANAGEMENT: 'enableConsentManagement',
  ENABLE_GLOBAL_NAVIGATION: 'EnableGlobalNavigation',
  ENABLE_GOOGLE_ONE_TAP: 'EnableGoogleOneTap',
  ENABLE_GOOGLE_ONE_TAP_BANANA: 'EnableGoogleOneTapBanana',
  ENABLE_GOOGLE_TAG_MANAGER: 'enable_google_tag_manager',
  ENABLE_GRAPHIC_PROMO: 'EnableGraphicPromo',
  ENABLE_GRAPPLER_INTEGRATION: 'EnableGrapplerIntegration',
  ENABLE_HOMEPAGE_HERO: 'EnableHomepageHero',
  ENABLE_INTERNAL_LINKING: 'EnableInternalLinking',
  ENABLE_INTERNATIONAL_SITES: 'enable_international_sites',
  ENABLE_INVALID_PAGE_TYPE_404: 'EnableInvalidPageType404',
  ENABLE_MOROCCO_RAMADAN_BANNER: 'EnableMoroccoRamadanBanner',
  ENABLE_PERIMETER_X: 'enable_perimeter_x',
  ENABLE_PERIMETER_X_FOR_ACORN: 'enable_perimeter_x_for_acorn',
  ENABLE_PERIMETER_X_FOR_BANANA: 'enable_perimeter_x_for_banana',
  ENABLE_PRERENDER: 'EnablePrerender',
  ENABLE_PRICE_CALENDAR_DATA: 'EnablePriceCalendarData',
  ENABLE_RAIL: 'EnableRail',
  ENABLE_RUSSIA_BANNER: 'EnableRussiaBannerBanana',
  ENABLE_SAVE_TO_LIST: 'EnableSaveToList',
  ENABLE_SONAR_ALPHA: 'enable_sonar_alpha',
  ENABLE_SPINNER_TIMEOUT_LOGGING: 'EnableSpinnerTimeoutLogging',
  ENABLE_US_BELARUS_BANNER: 'EnableUSBelarusBanner',
  FLIGHTS_DAY_VIEW_RESULTS_INTERVAL_BETWEEN_INLINE_ADS:
    'flights_day_view_results_interval_between_inline_ads',
  FOOTER_APP_DOWNLOAD_TRACKING_LINK: 'footerAppDownloadTrackingLink',
  FPS_DEGRADATION_SLOW_DOWN_POLLING: 'fps_degradation_slow_down_polling',
  GAM_ADS_INITIAL_DELAY: 'GamAdsInitialDelay',
  GOOGLE_CLIENT_ID_DEV: 'GoogleClientIdDev',
  GOOGLE_CLIENT_ID_PROD: 'GoogleClientIdProd',
  GOOGLE_PLAY_LINK: 'googlePlayLink',
  HEADER_CURRENCY_FORMAT: 'HeaderCurrencyDisplayFormat',
  HIDDEN_PQS_AGENTS: 'ABS_hide_pqs_partners',
  HIDDEN_PQS_PARTNERS: 'HiddenPqsPartners',
  HIDE_PQS_PARTNERS: 'ABS_hide_pqs_partners',
  HOTELS_CAROUSEL_WIDGET_SORTING: 'Component_hotels-carousel-widget_Sorting',
  IS_INTERNAL_TRAFFIC: 'is_internal_traffic',
  NUMBER_OF_ADDITIONAL_ADS_ON_INITIAL_RESULTS: 'banana_number_of_additional_ads_on_initial_results',
  PACKAGES_ALLOWED_DESTINATIONS: 'packages_allowed_destinations',
  PACKAGES_DISALLOWED_DESTINATION_COUNTRIES: 'packages_disallowed_destination_countries',
  PQS_FEEDBACK_FORM_PRESENTATION_DELAY: 'PQS_Feedback_Form_Presentation_Delay',
  PQS_FEEDBACK_FORM_PRESENTATION_DELAY_DESKTOP: 'pqs_feedback_form_presentation_delay_desktop',
  PRICE_REFRESH_INTERVAL: 'PriceRefreshInterval',
  PRICE_REFRESH_INTERVAL_BOOKING_PANEL: 'price_refresh_interval',
  PROD_GOOGLE_CLIENT_ID: 'PROD_Google_Client_ID',
  REDIRECT_TO_BOOKING_PANEL: 'redirect_to_booking_panel',
  REMOVE_SECOND_AD_IF_IDENTICAL_TO_FIRST: 'remove_second_ad_if_identical_to_first',
  RESULTS_INTERVAL_BETWEEN_INLINE_ADS: 'banana_interval_between_inline_ads',
  RESULT_ELEMENTS_PER_SCROLL: 'result_elements_per_scroll',
  SAVE_TO_LIST_V1_BASE_URL: 'save_to_list_v1_base_url',
  SAVE_TO_LIST_V2_BASE_URL: 'save_to_list_v2_base_url',
  SEARCH_RESULTS_OPTION_EVENT_AMOUNT: 'search_results_option_amount',
  SERVE_PRE_RELEASE_LOCALES: 'serve_pre_release_locales',
  SHOW_COMBINED_RESULTS_CAR_HIRE: 'ShowCombinedResultsCarHire',
  SHOW_COMBINED_RESULTS_HOTELS: 'ShowCombinedResultsHotels',
  SHOW_ECO_FILTER: 'TERRA_show_eco_filter',
  SHOW_GOOGLE_INLINE_ADS: 'ShowGoogleInlineAds',
  SHOW_INLINE_ADS: 'ShowInlineAds',
  SHOW_LOCALE_CODE: 'showLocaleCode',
  SHOW_PQS_SCORE: 'show_pqs_score',
  TOTAL_NUMBER_OF_VENDORS: 'totalNumberOfVendors',
  TRANSPARENCY_CONSENT_FRAMEWORK_CONFIG_VERSION: 'transparencyConsentFrameworkConfigVersionNumber',
} as const;

export const DATA = {
  FSS_BOOKING_PANEL_CTA_LABEL: 'fss_booking_panel_cta_label',
  FSS_DAY_VIEW_CTA_LABEL: 'fss_results_cta_label',
  FSS_MARKET_PROVIDER_FEATURES: 'fss_market_provider_features',
} as const;
